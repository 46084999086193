<template>
  <div class="row">
    <div class="col-12 col-lg-7 c">
        <div class="card card-body">
            <div v-if="step == 1">
                اختر الصفوف والفصول
                <hr>
                <div class="form-group col-12 col-lg-6">
                  <label for="">التاريخ</label>
                  <input type="date"
                    class="form-control" v-model="date">
                </div>
                <div v-for="c in classes" :key="c._id" class="g col-12">
                    {{ c.title }} <a href="javascript:;" @click="selecAllClass(c.title)"><small>اختيار الكل</small></a>
                    <br>
                    <span class="text-muted">.......</span>
                    <br>
                    <b-form-checkbox v-for="cl in c.classrooms" :key="c.title + '-' + cl" class='ddd'
                        v-model="selected_classes"
                        :value="c.title + '-' + cl"
                    >
                        {{ cl }}
                    </b-form-checkbox>
                </div>
                <div class="col-12 text-center g" v-if="selected_classes.length">
                    <button class="btn btn-primary" @click="step = 2; getStudents()">
                        تحضير الطلاب الان
                    </button>
                </div>
            </div>
            <div v-if="step == 2" class="col-12 text-center">
                <div class="col-12 table-responsive">
                    
                <div v-if="pending > 0">
                    <div class="alert alert-primary text-center g" style="padding: 40px">
                        <img :src="require('@/assets/images/loading-server.gif')" alt="" style="max-width: 100%">
                        <br>
                        <h5 class="g">
                            جاري الآن معالجة بعض السجلات,
                            جاري معالجة ( {{ pending }} ) تقرير...
                            <br>
                        </h5>
                        برجاء الانتظار حتى يظهر لك التقرير الصحيح
                        <i class="text-muted">ستيم الانتهاء بعد حوالي {{ after }} ثانية.</i>
                        <br><br>
                        <button class="btn btn-primary" @click="getReport()">
                            <i class="fa fa-refresh"></i>
                            اعادة المحاولة
                        </button>
                        <br><br>
                        اذا كنت لا تريد معرفة الحالة الحالية للطلاب يمكنك تحضيرهم الان مباشرة
                     </div>
                </div>
                    <b-dropdown id="dropdown-left" text="تغيير الكل الى" variant="primary" class="m-2">
                        <b-dropdown-item
                        @click="changeAllTo('1')"
                        >
                        حاضر
                        </b-dropdown-item>
                        <b-dropdown-item
                        @click="changeAllTo('2')"
                        >
                        متأخر
                        </b-dropdown-item>
                        <b-dropdown-item
                        @click="changeAllTo('3')"
                        >
                        غائب
                        </b-dropdown-item>
                        <b-dropdown-item
                        @click="changeAllTo('5')"
                        >
                        غائب بعذر
                        </b-dropdown-item>
                        <b-dropdown-item
                        @click="changeAllTo('4')"
                        >
                        انصراف
                        </b-dropdown-item>
                    </b-dropdown>
                    <div class="col-12 col-lg-5 text-left c g">
                        <div class="form-group">
                          <span for="">التوقيت</span>
                          <input type="time"
                            class="form-control" v-model="time">
                        </div>
                    </div>
                    <table class="table table-sm table-hover table-bordered">
                        <thead>
                            <th>
                                الطالب
                            </th>
                            <th>
                                الحالة
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="student in students" :key="student._id">
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    <select v-model="student.status" :class="'bg-' + student.status">
                                        <option value="">-- اختر --</option>
                                        <option value="1">حاضر</option>
                                        <option value="2">متأخر</option>
                                        <option value="3">غائب</option>
                                        <option value="5">غائب بعذر</option>
                                        <option value="4">انصراف</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 g">
                    <button class="btn btn-primary" @click="save()" v-if="!saving">
                        حفظ الان
                    </button>
                    <button class="btn btn-primary disabled" disabled v-if="saving">
                        جاري الحفظ...
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox,BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,BDropdown, BDropdownItem
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            saving: false,
            user: JSON.parse(localStorage.getItem('user')),
            students: [],
            classes: [],
            selected_classes: [],
            step: 1,

            pending: 0,
            after: 0,
            arr: [],
            date: new Date().toISOString().split("T")[0],
            reports: {},
            time: new Date().toISOString().split("T")[1].split(".")[0].split(":")[0] + ':' + new Date().toISOString().split("T")[1].split(".")[0].split(":")[1]
        }
    },
    created(){
        if(!checkPer("students|scan|bulk")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.classes = r.response
            }
        }).fail(function(){
        })
    },
    methods: {
        getReport(){
            var g = this;
            var arr2 = [];
            $.post(api + '/user/students/reports/daily', {
                jwt: this.user.jwt,
                date: g.date
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    r.response.forEach(function(a){
                        g.reports[a.number] = a.status;
                    })

                    g.pending = r.pending
                    g.after = r.after
                    g.students.forEach(function(b){
                        if(g.reports[b.number]){
                            b.status = g.reports[b.number]?.toString();
                        }
                        arr2.push(b)
                    })
                    g.students = arr2
                }
            }).fail(function(){
            })
        },
        selecAllClass(title){
            var g = this;
            $('.ddd').each(function(){
                if($(this).find('input').val().includes(title + '-')){
                    $(this).find('input').click()
                }
            })
        },
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.user.jwt,
                page: 1,
                perpage: 11111
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    r.response.forEach(function(a){
                        if(g.selected_classes.includes(a.classname + '-' + a.classroom)){
                            a.status = ''
                            g.students.push(a)
                        }
                    })
                    g.getReport()
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        changeAllTo(s){
            var g = this;
            g.students = g.students.map(function(x){
                x.status = s
                return x;
            })
        },
        save(){
            var g = this;
            g.arr = [];
            var ada = g.date + " " + g.time;
            g.students.forEach(function(a){
                if(a.status != '' && a.status){
                    g.arr.push({
                        name: a.name,
                        number: a.number,
                        time: ada,
                            custom_status: a.status
                    });
                }
            })
            g.uploadLogs()
        },
        uploadLogs(){
            var g = this;
            if(g.arr.length){
                g.saving = true;
                $.post(api + '/user/students/reports/upload', {
                    jwt: this.user.jwt,
                    reports: JSON.stringify(g.arr.map(function(x){
                        return {
                            number: x.number,
                            time: x.time,
                            custom_status: x.custom_status,
                            from: 'bulk'
                        }
                    })),
                }).then(function(r){
                    g.saving = false;
                    if(r.status == 100){
                        g.arr = [];
                        alert("تم بنجاح!", 100)
                        setTimeout(() => {
                            location.reload()
                        }, 2000);
                    }
                }).fail(function(){
                    g.saving = false;
                        location.reload()
                })
            }else{
                location.reload()
            }
        }
    }
}
</script>

<style>
.bg-1{
    background: #BED754;
}
.bg-2{
    background: #EEC759;
}
.bg-3{
    background: #FFAD84;
}
.bg-4{
    background: #F4F27E;
}
.bg-5{
    background: #8ACDD7;
}
</style>